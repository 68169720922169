<template>
    <div>
        <img :src="require('@/assets/mfmstop.jpg')" style="max-width: 100%;display: block;position: fixed;z-index: 1000" @click="download">
        <div style="height: 64.4px;"></div>
        <!-- <div style="text-align: center;position: absolute;z-index: 999;padding-top:486px;left: 26%;"> -->
        <div style="text-align: center;position: absolute;z-index: 999;margin-top:486px;width: 100%;">
            <img :src="require('@/assets/mfmsbts.png')" style="max-width: 100%;height: 4rem;" @click="download">
        </div>
        <van-swipe style="height: 550px;" class="my-swipe" :autoplay="3000" indicator-color="white" >
            <van-swipe-item><img :src="require('@/assets/mfms01.png')" width="100%"></van-swipe-item>
            <van-swipe-item><img :src="require('@/assets/mfms02.png')" width="100%"></van-swipe-item>
        </van-swipe>
        <van-tabs v-model="active" type="card">
            <van-tab title="火爆开服">
                <van-row v-for="(item, key) in data.list" :key="key" v-show="item.notice_type === 1" style="text-align: center;border-bottom: 1px solid #ebedf0;font-size: 14px;">
                    <van-col span="8"><p>{{ item.title }}</p></van-col>
                    <van-col span="8"><p style="color: red">{{ item.content }}</p></van-col>
                    <van-col span="8"><p>{{ item.released_at }}</p></van-col>
                </van-row>
                <!-- <van-row style="text-align: center;border-bottom: 1px solid #ebedf0;font-size: 14px;">
                    <van-col span="8"><p>雪花6区</p></van-col>
                    <van-col span="8"><p style="color: red">火爆开启</p></van-col>
                    <van-col span="8"><p>2020-11-14</p></van-col>
                </van-row> -->
                <van-row style="text-align: center;border-bottom: 1px solid #ebedf0;font-size: 14px;">
                    <van-col span="8"><p>雪花5区</p></van-col>
                    <van-col span="8"><p style="color: red">火爆开启</p></van-col>
                    <van-col span="8"><p>2020-11-12</p></van-col>
                </van-row>
                <van-row style="text-align: center;border-bottom: 1px solid #ebedf0;font-size: 14px;">
                    <van-col span="8"><p>雪花4区</p></van-col>
                    <van-col span="8"><p style="color: red">火爆开启</p></van-col>
                    <van-col span="8"><p>2020-11-09</p></van-col>
                </van-row>
                <van-row style="text-align: center;border-bottom: 1px solid #ebedf0;font-size: 14px;">
                    <van-col span="8"><p>雪花3区</p></van-col>
                    <van-col span="8"><p style="color: red">火爆开启</p></van-col>
                    <van-col span="8"><p>2020-11-07</p></van-col>
                </van-row>
                <van-row style="text-align: center;border-bottom: 1px solid #ebedf0;font-size: 14px;">
                    <van-col span="8"><p>雪花2区</p></van-col>
                    <van-col span="8"><p style="color: red">火爆开启</p></van-col>
                    <van-col span="8"><p>2020-11-05</p></van-col>
                </van-row>
                <van-row style="text-align: center;border-bottom: 1px solid #ebedf0;font-size: 14px;">
                    <van-col span="8"><p>雪花1区</p></van-col>
                    <van-col span="8"><p style="color: red">火爆开启</p></van-col>
                    <van-col span="8"><p>2020-11-03</p></van-col>
                </van-row>
                <van-row style="text-align: center;border-bottom: 1px solid #ebedf0;font-size: 14px;">
                    <van-col span="8"><p>热血再燃</p></van-col>
                    <van-col span="8"><p style="color: red">火爆开启</p></van-col>
                    <van-col span="8"><p>2020-10-16</p></van-col>
                </van-row>
            </van-tab>
            <van-tab title="游戏公告">
                <van-cell-group>
                    <van-cell v-for="(item, key) in data.list" :key="key" v-show="item.notice_type === 2" :title="item.title" :value="item.released_at" :url="item.url"/>
                    <!-- <van-cell title="《玛法灭世》雪花6区11月14日开启" value="2020-11-14" url="https://mp.weixin.qq.com/s/gA1GOWHOuvH4sP6y-oj-bA"/> -->
                    <van-cell title="《玛法灭世》雪花5区11月12日开启" value="2020-11-12" url="https://mp.weixin.qq.com/s/gA1GOWHOuvH4sP6y-oj-bA"/>
                    <van-cell title="《玛法灭世》雪花4区11月9日开启" value="2020-11-07" url="https://mp.weixin.qq.com/s/gA1GOWHOuvH4sP6y-oj-bA"/>
                    <van-cell title="《玛法灭世》雪花3区11月7日开启" value="2020-11-07" url="https://mp.weixin.qq.com/s/gA1GOWHOuvH4sP6y-oj-bA"/>
                    <van-cell title="《玛法灭世》雪花2区11月5日开启" value="2020-11-05" url="https://mp.weixin.qq.com/s/gA1GOWHOuvH4sP6y-oj-bA"/>
                    <van-cell title="《玛法灭世》雪花1区11月3日开启" value="2020-11-03" url="https://mp.weixin.qq.com/s/gA1GOWHOuvH4sP6y-oj-bA"/>
                    <van-cell title="《玛法灭世》欧美首服今日热血开启" value="2020-10-16" url="https://mp.weixin.qq.com/s/qmWRRIRpKp9XV8rgCe_KLQ"/>
                </van-cell-group>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import { fetchList } from "@/api/notice"
import { Base64 } from 'js-base64'
export default {
    data(){
        return {
            active: 2,
            data: {
                list: [],
                download: {}
            },
            filter: {
                game_id: 'e76ff9acb8004cb491c7a09997ea3389'
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        download(){
          const u = navigator.userAgent;
          const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
          let redirect_url = ''
          if (isiOS) {
            redirect_url = Base64.encodeURL(this.data.download.ios_download)
            // redirect_url = Base64.encodeURL('https://zaxhiyw.cn:7539/j7CH.html')
              // window.location.href = 'https://downloadappapkios.com/j7CH.html';
          } else {
            redirect_url = Base64.encodeURL(this.data.download.android_download)
            // redirect_url = Base64.encodeURL('https://zaxhiyw.cn:7539/j7CH.html')
              // window.location.href = 'https://downloadappapkios.com/j7CH.html';
          }
          this.$router.push({
            path: '/redirect',
            query: {
              url: redirect_url
            }
          })
        },
        getList() {
            fetchList(this.filter).then(response => {
                this.data.list = response.list
                this.data.download = response.download
            }).catch(() => {
            })
        }
    }
}
</script>

<style>
    .van-tabs__nav--card{
        margin: 0 !important;
    }
    .van-cell__title{
        min-width: 70%;
    }
</style>